/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Jefferson Camacho",
  description:
  "I am a passionate Full Stack Developer with experience in developing web projects and a strong ability to collaborate effectively within teams. I specialize in creating comprehensive technological solutions, from frontend design to backend logic, always with enthusiasm and dedication.",
    og: {
    title: "Jefferson Camacho Portfolio",
    type: "website",
    url: "https://softdesarrolladores.com.com/",
  },
};

//Home Page
const greeting = {
  title: "Jefferson Camacho",
  logo_name: "JeffersonCamacho",
  nickname: "jeffer",
  subTitle:
    "Soy un apasionado Desarrollador Full Stack con experiencia en el desarrollo de proyectos web y una gran habilidad para colaborar eficazmente en equipos. Me especializo en la creación de soluciones tecnológicas integrales, desde el diseño Frontend hasta la lógica Backend, siempre con entusiasmo y dedicación.",
  resumeLink:
    "https://drive.google.com/file/d/1kfA-Yx6V55CPmG7cCnGN5IS7afN4I_yk/view?usp=sharing",
  portfolio_repository: "https://github.com/jfcamacho",
  githubProfile: "https://github.com/jfcamacho",
};

const socialMediaLinks = [
  /* Your Social Media Link */
  // github: "https://github.com/ashutosh1919",
  // linkedin: "https://www.linkedin.com/in/ashutosh-hathidara-88710b138/",
  // gmail: "ashutoshhathidara98@gmail.com",
  // gitlab: "https://gitlab.com/ashutoshhathidara98",
  // facebook: "https://www.facebook.com/laymanbrother.19/",
  // twitter: "https://twitter.com/ashutosh_1919",
  // instagram: "https://www.instagram.com/layman_brother/"

  {
    name: "Github",
    link: "https://github.com/jfcamacho",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/jefferson-camacho-323b0b1ba/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  // {
  //   name: "YouTube",
  //   link: "https://youtube.com/c/DevSense19",
  //   fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
  //   backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  // },
  {
    name: "Hotmail",
    link: "mailto:jefferson-camacho@hotmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  // {
  //   name: "X-Twitter",
  //   link: "https://twitter.com/ashutosh_1919",
  //   fontAwesomeIcon: "fa-x-twitter", // Reference https://fontawesome.com/icons/x-twitter?f=brands&s=solid
  //   backgroundColor: "#000000", // Reference https://simpleicons.org/?q=x
  // },
  // {
  //   name: "Facebook",
  //   link: "https://www.facebook.com/laymanbrother.19/",
  //   fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
  //   backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  // },
  // {
  //   name: "Instagram",
  //   link: "https://www.instagram.com/layman_brother/",
  //   fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
  //   backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  // },
];

const skills = {
  data: [
    {
      title: "Front End Development",
      fileName: "DesignImg",
      skills: [
        "⚡ Creación de aplicaciones web utilizando frameworks modernos como Angular y React, asegurando una adaptación fluida a distintos dispositivos.",
        "⚡ Diseño de interfaces atractivas con frameworks CSS como Bootstrap o PrimeFlex, priorizando la accesibilidad y usabilidad",
        "⚡ Aplicación de técnicas de lazy loading y gestión eficiente de recursos para mejorar la velocidad de carga",
      ],
      softwareSkills: [
        {
          skillName: "Figma",
          fontAwesomeClassname: "simple-icons:figma",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Adobe Illustrator",
          fontAwesomeClassname: "simple-icons:adobeillustrator",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "AngularJS",
          fontAwesomeClassname: "vscode-icons:file-type-angular",
          style: {
            color: "#ff4013",
          },
        },
        {
          skillName: "React",
          fontAwesomeClassname: "skill-icons:react-dark",
          style: {
            color: "#ff4013",
          },
        },
        {
          skillName: "PrimeNg",
          fontAwesomeClassname: "simple-icons:primeng",
          style: {
            color: "#ff4013",
          },
        },
      ],
    },
    {
      title: "Back End Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Desarrollo de APIs RESTful con NestJS o Express para habilitar conexiones de datos en tiempo real.",
        "⚡ Estructuración y mantenimiento de modelos de datos con PostgreSQL y MySQL, optimizando consultas mediante Sequelize o TypeORM.",
        "⚡ Implementación de autenticación con JWT y OAuth2, integrando proveedores externos como Auth0 para simplificar el acceso de usuarios.",
        "⚡ Creación de tests con frameworks como Jest o Jasmine para garantizar la calidad del código, y documentación de APIs usando Swagger para facilitar la integración."
      ],
      softwareSkills: [
        
        {
          skillName: "Python",
          fontAwesomeClassname: "logos:python",
          style: {
            color: "#ff4013",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "skill-icons:nodejs-dark",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "NPM",
          fontAwesomeClassname: "simple-icons:npm",
          style: {
            color: "#CB3837",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "devicon:java-wordmark",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Javascript",
          fontAwesomeClassname: "logos:javascript",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "NestJs",
          fontAwesomeClassname: "logos:nestjs",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Jasmine",
          fontAwesomeClassname: "vscode-icons:file-type-jasmine",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "JWT",
          fontAwesomeClassname: "logos:jwt-icon",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Auth0",
          fontAwesomeClassname: "logos:auth0-icon",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "TypeORM",
          fontAwesomeClassname: "logos:typeorm",
          style: {
            color: "#2C8EBB",
          },
        },
        {
          skillName: "Sequelize",
          fontAwesomeClassname: "vscode-icons:file-type-sequelize",
          style: {
            color: "#2C8EBB",
          },
        },
      ],
    },
    {
      title: "DevOps y Seguridad",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Automatización de despliegues en AWS para asegurar alta disponibilidad y escalabilidad.",
        "⚡ Uso de Docker para la contenerización de aplicaciones y Kubernetes para la gestión de la infraestructura.",
        "⚡ Integración de pipelines CI/CD con GitHub Actions para facilitar despliegues rápidos y seguros.",
      ],
      softwareSkills: [
        // {
        //   skillName: "Docker",
        //   fontAwesomeClassname: "vscode-icons:file-type-dockertest2",
        //   style: {
        //     color: "#4285F4",
        //   },
        // },
        {
          skillName: "AWS",
          fontAwesomeClassname: "skill-icons:aws-dark",
          style: {
            color: "#FF9900",
          },
        },
        // {
        //   skillName: "Azure",
        //   fontAwesomeClassname: "simple-icons:microsoftazure",
        //   style: {
        //     color: "#0089D6",
        //   },
        // },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "logos:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "PostgreSQL",
          fontAwesomeClassname: "devicon:postgresql-wordmark",
          style: {
            color: "#336791",
          },
        },
        // {
        //   skillName: "MongoDB",
        //   fontAwesomeClassname: "simple-icons:mongodb",
        //   style: {
        //     color: "#47A248",
        //   },
        // },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    {
      title: "Colaboración y Gestión de proyectos",
      fileName: "DataScienceImg",
      skills: [
        "⚡ Participación en sprints ágiles, incluyendo reuniones diarias, planificación de sprints y retrospectivas.",
        "⚡ Ejecución de revisiones de código para asegurar la calidad del software alineada con las mejores prácticas de la industria.",
        "⚡ Mentoría a desarrolladores junior para fomentar su crecimiento e integración en el equipo.",
        "⚡ Traducción de los requisitos del negocio en soluciones técnicas alineadas con los objetivos del proyecto.",
      ],
      softwareSkills: [
        {
          skillName: "Trello",
          fontAwesomeClassname: "devicon:trello",
          style: {
            color: "#FF2BC2",
          },
        },
        {
          skillName: "Slack",
          fontAwesomeClassname: "logos:slack-icon",
          style: {
            color: "#F24E1E",
          },
        },
        {
          skillName: "Microsoft Teams",
          fontAwesomeClassname: "logos:microsoft-teams",
          style: {
            color: "#FF7C00",
          },
        },
        {
          skillName: "GitHub",
          fontAwesomeClassname: "logos:github-icon",
          style: {
            color: "#000000",
          },
        },
        {
          skillName: "Discord",
          fontAwesomeClassname: "logos:discord-icon",
          style: {
            color: "#000000",
          },
        },
      ],
    },
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "LeetCode",
      iconifyClassname: "simple-icons:leetcode",
      style: {
        color: "#F79F1B",
      },
      profileLink: "https://leetcode.com/jfcamacho/",
    },
    {
      siteName: "HackerRank",
      iconifyClassname: "simple-icons:hackerrank",
      style: {
        color: "#2EC866",
      },
      profileLink: "https://www.hackerrank.com/profile/jefferson_camac1",
    },
    // {
    //   siteName: "Codechef",
    //   iconifyClassname: "simple-icons:codechef",
    //   style: {
    //     color: "#5B4638",
    //   },
    //   profileLink: "https://www.codechef.com/users/ashutosh_1919",
    // },
    // {
    //   siteName: "Codeforces",
    //   iconifyClassname: "simple-icons:codeforces",
    //   style: {
    //     color: "#1F8ACB",
    //   },
    //   profileLink: "http://codeforces.com/profile/layman_brother",
    // },
    // {
    //   siteName: "Hackerearth",
    //   iconifyClassname: "simple-icons:hackerearth",
    //   style: {
    //     color: "#323754",
    //   },
    //   profileLink: "https://www.hackerearth.com/@ashutosh391",
    // },
    // {
    //   siteName: "Kaggle",
    //   iconifyClassname: "simple-icons:kaggle",
    //   style: {
    //     color: "#20BEFF",
    //   },
    //   profileLink: "https://www.kaggle.com/laymanbrother",
    // },
  ],
};

const degrees = {
  degrees: [
    {
      title: "Universidad Nelson Mandela",
      subtitle: "Diplomado de Programación en Java",
      logo_path: "unm-01.png",
      alt_name: "Escuela de Negocios Nelson Mandela",
      duration: "2021 - 2022",
      descriptions: [
        "⚡ Desarrollo de aplicaciones modulares y reutilizables aplicando patrones de diseño para optimizar el desarrollo y mejorar la mantenibilidad del software.",
        "⚡ Creación de aplicaciones web utilizando frameworks como Spring Boot y Java EE, con integración de bases de datos mediante JDBC, Hibernate o JPA. Además, se configura y consume APIs RESTful, facilitando la comunicación entre servicios y aplicaciones.",
        "⚡ Pruebas automatizadas con JUnit para garantizar la calidad del software, y despliegue en entornos productivos, integrando herramientas como Maven y Docker para automatización y eficiencia.",
      ],
      website_link: "https://www.unmnelsonmandela.com/",
    },
    {
      title: "Universidad Politécnica de Valencia",
      subtitle: "Máster Universitario en Automática e Informática Industrial",
      logo_path: "upv_data.png",
      alt_name: "Universidad Politécnica de Valenia España",
      duration: "2015 - 2016",
      descriptions: [
        "⚡ He tomado varios cursos sobre programación de computadores, transmisión y manipulación de datos y optimización de procesos.",
        "⚡ Aparte de esto he desarrollado diferentes investigaciones sobre adquisición de datos en tiempo real y tratamiento de los mismos.",
        "⚡ Durante mi estadía en la universidad estuve asociado al departamento de investigación de robótica del cuál desarrolle mi proyecto de fin de máster.",
      ],
      website_link: "https://www.upv.es/",
    },
    {
      title: "Universidad Técnica Particular de Loja",
      subtitle: "Ingeniería en Electrónica y Telecomunicaciones",
      logo_path: "utpl-01.png",
      alt_name: "UTPL",
      duration: "2008 - 2014",
      descriptions: [
        "⚡ He estudiado resolución de problemas diseño de sistemas eléctricos, electrónicos y programación funcional",
        "⚡ Aparte de esto he seguido cursos sobre, programación de micro-controladores, y diseño de placas electrónicas",
        "⚡ Conseguí una beca del 80% por responsabilidad universitaria destinada a aquellos estudiantes involucrados en proyectos de desarrollo que generen impacto.",
      ],
      website_link: "https://www.utpl.edu.ec/",
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Problem Solving",
      subtitle: "- HackerRank",
      logo_path: "HackerRank.png",
      certificate_link:
        "https://www.hackerrank.com/certificates/iframe/7a2c5fbb84d5",
      alt_name: "HackerRank",
      color_code: "rgb(6,12,23)",
    },
    {
      title: "Cloud Practicioner",
      subtitle: "- Amazon Web Service",
      logo_path: "aws.png",
      certificate_link:
        "https://www.credly.com/badges/e2043b8a-2028-4d20-879a-1facda0d4669",
      alt_name: "Amazon Web Service",
      color_code: "rgb(38,47,61)",
    },
    {
      title: "FullStack Developer",
      subtitle: "- HENRY",
      logo_path: "HenryLogo.bb57fd6f.svg",
      certificate_link:
        "https://view.pok.tech/c/54f66923-dfec-417d-8d46-c964080aac84",
      alt_name: "SoyHenry",
      color_code: "rgb(255,255,255)",
    },
    {
      title: "Node Js",
      subtitle: "- HackerRank",
      logo_path: "HackerRank.png",
      certificate_link:
        "https://www.hackerrank.com/certificates/iframe/5f8789da58b2",
      alt_name: "HackerRank",
      color_code: "rgb(6,12,23)",
    },
    {
      title: "Node Js Creando API con Express y MongoDB",
      subtitle: "- Grover Vásquez",
      logo_path: "udemy.webp",
      certificate_link:
        "https://www.udemy.com/certificate/UC-c64d87fc-8f23-40bd-b1e9-92eba30d9914/",
      alt_name: "Udemy",
      color_code: "#0C9D5899",
    },
    {
      title: "Java",
      subtitle: "- HackerRank",
      logo_path: "HackerRank.png",
      certificate_link:
        "https://www.hackerrank.com/certificates/iframe/3e9f11077801",
      alt_name: "HackerRank",
      color_code: "rgb(6,12,23)",
    },
    {
      title: "Microservicios con NodeJS, Docker y Kubernetes",
      subtitle: "- Sergio Hidalgo",
      logo_path: "cursosdev.png",
      certificate_link:
        "https://cursos-dev.com/certificate/Z4ZPULVXhT",
      alt_name: "Cursos Dev",
      color_code: "rgb(127,200,233)",
    },
    {
      title: "GOLANG: Curso profesional de Go",
      subtitle: "- Roel Code",
      logo_path: "udemy.webp",
      certificate_link:
        "https://www.udemy.com/certificate/UC-7a1e94dd-7fb7-430c-92e3-6777e9417fb8/",
      alt_name: "Udemy",
      color_code: "#0C9D5899",
    },
  ],
};

// Experience Page
const experience = {
  title: "Experiencia",
  subtitle: "Trayectoria Profesional y Freelance en Tecnología",
  description:
    "Amplia experiencia en desarrollo de software, participando en la implementación de soluciones tecnológicas, además de proporcionar soporte técnico, garantizar la operatividad de sistemas y optimizar procesos mediante herramientas digitales. Asimismo, he impartido clases a estudiantes universitarios, contribuyendo activamente a su formación en desarrollo de software y tecnología",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      work: true,
      experiences: [
        {
          title: "Full Stack Developer",
          company: "Freelance.",
          company_url: "https://www.softdesarrolladores.com",
          logo_path: "logo.svg",
          duration: "Enero 2017 - Presente",
          location: "Remoto",
          description: "Desarrollo Frontend utilizando Angular, React, Bootstrap, TypeScript y JavaScript, junto con Backend en Node.js y Java, abarcando la creación de APIs, gestión de bases de datos e integración de servicios externos. Competencia en la configuración de entornos de producción, gestión de versiones y resolución de problemas de despliegue. Habilidad para coordinar proyectos, gestionar plazos y comunicar de manera efectiva con los clientes, adaptando los requerimientos y asegurando la entrega de soluciones eficientes.",
          color: "#000000",
        },
        {
          title: "Profesor Universitario",
          company: "Universidad Nacional de Loja",
          company_url: "https://unl.edu.ec/",
          logo_path: "UNL-01.png",
          duration: "Octubre 2018 - Presente",
          location: "Loja, Loja, Ecuador",
          description:
            "Desarrollo de investigaciones avanzadas y publicación de artículos en revistas científicas, orientación académica y profesional a estudiantes en proyectos y prácticas, colaboración con colegas en comités y eventos académicos, y planificación e impartición de clases mediante métodos pedagógicos innovadores para garantizar el éxito académico.",
          color: "#0879bf",
        },
        {
          title: "Oficial de Seguridad de la información",
          company: "CACPE LOJA",
          company_url: "https://cacpelojaenlinea.fin.ec/",
          logo_path: "CACPE_LOJA.png",
          duration: "Jul 2016 - Oct 2018",
          location: "Loja, Ecuador",
          description:
            "Implementación y mantenimiento de políticas de seguridad para proteger la información, con auditorías, gestión de riesgos y respuesta a incidentes. Desarrollo de software seguro mediante prácticas de codificación robustas y pruebas rigurosas. Evaluación continua de nuevas tecnologías para mejorar la infraestructura de TI. Colaboración con otros departamentos para integrar seguridad en el ciclo de desarrollo y capacitación del personal, promoviendo una cultura de protección en toda la organización.",
          color: "#9b1578",
        },
        {
          title: "Soporte Tecnológico",
          company: "CACPE LOJA",
          company_url: "https://cacpelojaenlinea.fin.ec/",
          logo_path: "CACPE_LOJA.png",
          duration: "Jul 2012 - Jun 2015",
          location: "Loja, Ecuador",
          description:
            "Gestión integral de soporte tecnológico, incluyendo resolución de problemas, desarrollo de software y mantenimiento de bases de datos. Entre las funciones principales destacan la atención a usuarios, asegurando la operatividad de sistemas y herramientas, junto con la implementación de soluciones técnicas. También realiza mantenimiento y optimización de bases de datos, garantizando su rendimiento, así como la migración de sistemas, minimizando riesgos y asegurando una transición fluida. Además, colabora con otras áreas para desarrollar procesos tecnológicos eficientes y brindar soporte continuo post-implementación.",
          color: "#9b1578",
        },
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Proyectos",
  description:
    "Mis proyectos usan una gran cantidad de las últimas tegnologías. Mi experiencia en desarrollo va desde el diseño hasta la implementación en monolitos o microservicios.",
  avatar_image_path: "projects_image.svg",
};

const publicationsHeader = {
  title: "Publicaciones",
  description: "Algunos de los artículos en los cuales he particiado en su desarrollo.",
  avatar_image_path: "projects_image.svg",
};

const publications = {
  data: [
    {
      id: "neuro-symbolic-sudoku-solver",
      name: "Stochastic Economic Power Dispatch Problem Based on ARMA-Analogs Wind Power Scenarios",
      createdAt: "2023-07-02T00:00:00Z",
      description: "10.1109/C358072.2023.10436199",
      url: "https://ieeexplore.ieee.org/document/10436199",
    },
    {
      id: "mdp-diffusion",
      name: "Feasibility analysis of charging stations for electric vehicles. Case study route E35, Loja-Cuenca in Ecuador",
      createdAt: "2023-09-19T00:00:00Z",
      description: "Blog published in Paperspace",
      url: "https://blog.paperspace.com/mdp-diffusion/",
    },
  ],
};

// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contáctame",
    profile_image_path: "jeff_cartoon.png",
    description:
      "Estoy disponible a través de varios medios como LinkIn, email o medios sociales, Te responderé en menos de 24 horas. Te puedo ayudar con Desarrollo WEB, (Front y Back), Cloud y desarrollo de código abierto.",
  },
  blogSection: {
    title: "Blogs",
    subtitle:
      "I like to document some of my experiences in professional career journey as well as some technical knowledge sharing.",
    link: "https://blogs.ashutoshhathidara.com/",
    avatar_image_path: "blogs_image.svg",
  },
  addressSection: {
    title: "Address",
    subtitle: "Loja, Ecuador, ECU 110105",
    locality: "Loja",
    country: "ECU",
    region: "Loja",
    postalCode: "110105",
    streetAddress: "Amable Maria",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://maps.app.goo.gl/NvYZqa34Wye4tpS17",
  },
  phoneSection: {
    title: "",
    subtitle: "",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  publicationsHeader,
  publications,
  contactPageData,
};
